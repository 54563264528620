[class*='live-col']{
    margin: 3% 2%;
    margin-right: 0;
}
.live-col-1 {
    flex-basis: 27%;
    margin-left: 2%;
}

.live-request-row{
    display: flex;
    flex-flow: column;
    align-items: center;
    /*background-color: grey;*/
}

.live-request-row label{
    /*margin-top: 8px;*/
    cursor: pointer;
}

.live-request-row img{
    /*margin-top: 8px;*/
    width: 3.5em;
    cursor: pointer;
}

.live-request-row img:hover{
    transform: scale(1.2);
}

.live-request-row img:hover{

}

.live-label{
    font-size: 0.9em;
    flex-basis: 20%;
    color: #FD6864;
    font-family: "SansationLight";
    font-weight: bolder;
    margin-top:7px
}


/* Mobile Landscape Screen Sizes */
@media only screen and (min-width: 480px) {
}

/* Desktop screen Sizes */
@media only screen and (min-width: 768px) {
    [class*='live-col']{
        margin: 1% 2%;
        margin-right: 0;
    }
    .live-col-1 {
        flex-basis: 28.3%;
    }

    .live-request-row img{
        width: 3.5em;
    }

}