/* Define our color variables */

html {
  --primary-color: #1f2024;
  --secondary-color: #fff;
  --label-color: #ffffff80;
  --request-row: #1a191d;
  --label-background-select: #141417;
  --main-theme-color: #1f2024;
  --form-base-color: #211f24;
}

/* Redefine our color variables if the data-theme attr
  value is "dark
*/

html[data-theme="light"] {
  --primary-color: #fff;
  --secondary-color: #282c34;
  --label-color: #fff;
  --request-row: white;
  --label-background-select: #fff;
  --main-theme-color: #FD6864;
  --form-base-color: white;
}

body{
  background-color: var(--primary-color);
  /*background: url("/black.jpg");*/
  /*background-image: linear-gradient(rgba(0, 0, 0), rgba(0, 0, 0)), url(/black.jpg);*/
}
.App {
  /*text-align: center;*/
  min-height: 20%;
  margin: 1em;
  /*background-color: var(--primary-color);*/
  color: var(--secondary-color);

}

.theme-div{
  display: flex;
  justify-content: flex-end;
  margin: 0.8em 1.5em;
  min-height: 30px;
}
.theme-div img{
  /*width: 2vw;*/
}

.svg-dark{
  /*filter: invert(100%) sepia(100%) saturate(100%);*/
  /*heigth:0.1em;*/
  max-width: 30px;
  max-height: 30px;
}
.svg-light{
  max-width: 30px;
  max-height: 30px;
  filter: invert(0%) sepia(0%) saturate(0%);
}

.spinner-class{
  display: flex;
  flex-flow: row;
  justify-content: center;
  align-items: center;
  min-height: 50em;
}
.lds-hourglass {
  align-self: center;
  display: inline-block;
  position: relative;
  /*width: 80px;*/
  /*height: 100px;*/
}
.lds-hourglass:after {
  content: " ";
  display: block;
  border-radius: 50%;
  width: 0;
  height: 0;
  box-sizing: border-box;
  border: 75px solid #fff;
  border-color: #fff transparent #fff transparent;
  animation: lds-hourglass 1.2s infinite;
}
@keyframes lds-hourglass {
  0% {
    transform: rotate(0);
    animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
  }
  50% {
    transform: rotate(900deg);
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  100% {
    transform: rotate(1800deg);
  }
}

/* Mobile Landscape Screen Sizes */
@media only screen and (min-width: 480px) {
}

/* Desktop screen Sizes */
@media only screen and (min-width: 768px) {
  .App{
    margin: 2.5em;
    margin-top: 0.5em;
  }

  .svg-dark{
    /*filter: invert(100%) sepia(100%) saturate(100%);*/
    width: 2em;
  }
  .svg-light{
    width: 1.5em;
    filter: invert(0%) sepia(0%) saturate(0%);
  }
}
