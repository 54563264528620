.dev-request-main-row {
    /*display: flex;*/
    /*flex-flow: row wrap;*/
    /*justify-content: center;*/
    margin: 1%;
    font-size: calc(10px + 2vmin);
    border-radius: 14px;
    font-family: "Trebuchet MS";
    font-weight: bold;
    background-color: var(--request-row);
}

.dev-col {
    margin: 4% 0;
}


.method-col{
    padding-top: 3vh;
}
[class*="dev-request-col"] {
    /*margin-top: 3%;*/
    margin-left: 2%;
    padding: 0;
    font-size: calc(10px + 1vmin);
    margin-top: 5px;
    margin-bottom: 5px;
    /*background-color: red;*/
}

.dev-request-col-1 {
    /*background-color: orange;*/
    flex-basis: 70%;
}

.dev-request-col-1-2 {
    flex-basis: 24%;
}

.dev-request-col-2 {
    flex-basis: 96%;
}

.dev-request-col-4 {
    /*background-color: orange;*/
    flex-basis: 96%;
}

.ant-input-group-addon {
    font-size: 12px;
}

.ant-input-group-addon:first-child {
    border-right: solid 1px var(--main-theme-color)
}

.ant-input-group-wrapper {
    width: fit-content;
}

.dev-icon-col {
    border-right: none;
    display: flex;
    flex-flow: column;
    align-items: center;
    /*border-right: var(--secondary-color) 1px solid;*/
    padding-right: 14px;
}

.dev-icon-col img {
    width: 3vh;
    margin-bottom: 0.4vh;
    margin-top: 0.8vh;
}

.dev-input-group-col {
    /*margin-left: 2%;*/
    flex-basis: 98%;
    /*margin-top: 0.5vh;*/
    /*border-radius: 5px;*/
    justify-self: center;
}

.lbl {
    font-size: 2vh;
    margin-left: 0;
    color: #fd6864;
    font-family: SansationLight;
}

.ant-select-selector {
    /*min-width: 15em;*/
    font-size: 12px;
    max-height: 28px;
}

.url-col {
    padding-left: 14px;
}

.send-button {
    max-width: 90%;
}

.dev-col img{
    width: 8vw;
}

/* Mobile Landscape Screen Sizes */
@media only screen and (min-width: 480px) {

    .dev-icon-col img {
        width: 5vw;
    }
}

/* Desktop screen Sizes */
@media only screen and (min-width: 768px) {
    .dev-request-col-1-2 {
        flex-basis: 7%;
        /*margin-left: 2%;*/
    }

    .dev-request-col-1 {
        flex-basis: 12.5%;
    }

    .dev-request-col-2 {
        flex-basis: 60%;
    }

    .dev-request-col-4 {
        flex-basis: 12.5%;
    }

    .ant-select-selector {
        /*min-width: 15em;*/
        font-size: 12px;
        max-height: 28px;
    }

    /*.dev-icon-col {*/
    /*    !*border-right: solid 1px var(--secondary-color);*!*/
    /*    width: 8vh;*/
    /*    margin-bottom: 1.4vh;*/
    /*}*/

    .dev-col {
        margin: 1% 0;
    }

    .dev-col img{
        width: 3vw;
    }

    .input-url {
        /*width: 30%;*/
    }

    .icon-col img {
        width: 6vh;
        margin-bottom: 0.4vh;
        /*margin-top: 0.6vh;*/
    }

    .icon-col {
        padding-right: 17px;
    }

}