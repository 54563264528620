.progess-bar-div{
    display: flex;
    flex-flow: column;
    align-items: flex-start;
    margin: 2vh 0;
}

.progess-bar-div label{
    /*background-color: grey;*/
    /*padding: 1% 2%;*/
    /*border-radius: 10px;*/
    color: var(--secondary-color);
    font-size: 2vh;
}

.progess-bar-div img{
    width: 5%;
}

.ant-progress-text{
    color: var(--secondary-color);
}

.timeline-body-row img{
    width: 6vh;
}