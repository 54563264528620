.timeline-body-row {
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: flex-start;
  margin: 1%;
  font-size: calc(10px + 2vmin);
  border-radius: 20px;
  font-family: "Trebuchet MS";
  font-weight: bold;
  background-color: var(--request-row);
  color: var(--secondary-color);
}

.timeline-col-1 {
  margin-top: 1.5%;
  margin-bottom: 1%;
  margin-left: 4%;
  margin-right: 4%;
  flex-basis: 88%;
  align-self: flex-start;
  text-align: center;
}

.timeline-col-1 p {
  font-size: 1.5em;
  font-family: SansationLight;
}

.timeline-sec-row {
  order: -1;
  display: flex;
  flex-flow: column nowrap;
}

.timeline-info-div {
  align-self: flex-start;
}

/*.ant-timeline.ant-timeline-label .ant-timeline-item-label{*/
/*    text-align: left;*/
/*}*/
/*.ant-timeline.ant-timeline-alternate .ant-timeline-item-tail, .ant-timeline.ant-timeline-right .ant-timeline-item-tail, .ant-timeline.ant-timeline-label .ant-timeline-item-tail, .ant-timeline.ant-timeline-alternate .ant-timeline-item-head, .ant-timeline.ant-timeline-right .ant-timeline-item-head, .ant-timeline.ant-timeline-label .ant-timeline-item-head, .ant-timeline.ant-timeline-alternate .ant-timeline-item-head-custom, .ant-timeline.ant-timeline-right .ant-timeline-item-head-custom, .ant-timeline.ant-timeline-label .ant-timeline-item-head-custom{*/
/*    left:22%*/
/*}*/
/*.ant-timeline.ant-timeline-label .ant-timeline-item-left .ant-timeline-item-content {*/
/*    left: calc(25% - 4px);*/
/*    width: 50vh;*/
/*}*/

/*.ant-collapse-header{*/
/*    background-color: #2E2F33;*/
/*}*/
/*.ant-collapse{*/
/*    border-color: var(--primary-color);*/
/*}*/
/*.ant-collapse .ant-collapse-item{*/
/*    border-color: var(--primary-color);*/
/*}*/

/*.ant-collapse-content-box{*/
/*    background-color: var(--primary-color);*/
/*    border-color: var(--primary-color);*/
/*    color: var(--secondary-color);*/
/*}*/
/*.ant-collapse > .ant-collapse-item > .ant-collapse-header{*/
/*    color: white;*/
/*    font-size: 2.3vh;*/
/*    font-weight: bolder;*/
/*}*/
/* Timeline */
.timeline {
  border-left: 4px solid #fd6864;
  border-bottom-right-radius: 4px;
  border-top-right-radius: 4px;
  /*background: rgba(255, 255, 255, 0.03);*/
  color: var(--secondary-color);
  margin: 30px auto;
  letter-spacing: 0.5px;
  position: relative;
  line-height: 1em;
  font-size: 1.03em;
  padding: 50px;
  padding-top: 30px;
  padding-bottom: 30px;
  list-style: none;
  text-align: left;
  font-weight: 100;
  max-width: 100%;
  min-width: 90%;
  margin-top: 0;
  margin-left: 50px;
}

.timeline h1 {
  letter-spacing: 1.5px;
  font-weight: 400;
  font-size: 1.4em;
  color: var(--secondary-color)
}

.timeline h2,
.timeline h3 {
  letter-spacing: 1px;
  font-weight: 800;
  font-size: 1.8em;
  font-family: SansationLight;
  color: var(--secondary-color);
  margin-bottom: 0.5em;
}

.timeline h3 span {
  font-size: 0.6em;
}

.timeline .event {
  border-bottom: 1px dashed rgba(255, 255, 255, 0.1);
  padding-bottom: 25px;
  margin-bottom: 20px;
  position: relative;
  font-size: 0.5em;
}

.timeline .event:last-of-type {
  padding-bottom: 0;
  margin-bottom: 0;
  border: none;
}

.timeline .event:before,
.timeline .event:after {
  position: absolute;
  display: block;
  top: 0;
}

.timeline .event:before {
  left: -184.5px;
  color: var(--secondary-color);
  content: attr(data-date);
  text-align: right;
  font-weight: 200;
  font-size: 1.2em;
  min-width: 120px;
  font-weight: 600;
}

.timeline .event:after {
  box-shadow: 0 0 0 4px #fd6864;
  left: -57.85px;
  background: var(--primary-color);
  border-radius: 50%;
  height: 11px;
  width: 11px;
  content: "";
  top: 6px;
}

.timeline .event p {
  margin-bottom: 0;
  font-family: "archia";
  font-size: 1.1em;
  font-weight: 600;
  text-align: justify;
}

.span-color {
  color: #fd6864;
}

.projects-section {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  color: white;
  list-style-type: none;
}

.projects-section-list-item{
    color: #fd6864;
    font-size: large;
    font-family: "American Typewriter";
    list-style-type: none;
    margin: 0px;
    margin-left: 10px;
}

.projects-section-list-item:hover{
    color: white;
}

.projects-section li::before {
  content: "\2022";
  color: var(--secondary-color);
  display: inline-block;
  width: 0.8em;
  margin-left: -1em;
}


@font-face {
  font-family: "archia";
  src: local('archia'), url(../../../fonts/archia.woff) format('woff');
}

/* Mobile Landscape Screen Sizes */
@media only screen and (min-width: 480px) {
}

/* Desktop screen Sizes */
@media only screen and (min-width: 870px) {

  .timeline-col-1 {
    margin-left: 1%;
    margin-right: 1%;
    flex-basis: 48%;
    padding: 0 5%;
  }

  .timeline {
    margin-left: 30px;
  }

  .timeline .event:before {
    left: -200.5px;

  }

  .timeline-sec-row {
    order: 2;
  }
}
