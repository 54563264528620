.about-body-row{
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    /*align-items: center;*/
    margin: 1%;
    font-size: calc(10px + 2vmin);
    border-radius: 14px;
    font-family: "Trebuchet MS";
    font-weight: bold;
    background-color: var(--request-row);
    color: var(--secondary-color);
}

[class*='aboutme-col-']{
    align-self: center;
    margin: 1.5em 4%;
}

.aboutme-col-1{
    flex-basis: 92%;
}

.aboutme-col-2{
    flex-basis: 92%;
}

.about-me-image-sec{
    font-size: 4vmin;
    display: flex;
    flex-flow: column wrap;
}

.sunny-span{
    text-decoration: underline;
}

.about-me-image-sec p{
    font-size: 1.6em;
    padding-top: 1em;
    text-align: center;
    font-family: SansationLight
}

.self-image{
    width: 50vw;
    height: 50vw;
    border-radius: 7em;
    margin: 0.2em 4.5em;
    padding: 4px;
    border: solid 3px #fd6864;
    align-self: center;
}

.self-image:hover{
    /*transform: scale(1.1);*/
    /*margin-right: 0;*/
}

.wave-icon{
    width: 1.2em;
}
.wave-icon:hover{
    transform: rotate(25deg);
}

.about-me-info{
    text-align: justify;
    font-family: "Roboto Mono Custom";
    font-size: 2.7vh;
}

@font-face {
    font-family: "Roboto Mono Custom";
    src: local('Roboto Mono Custom.ttf'), url(../../../fonts/RobotoMono-Regular.ttf) format('truetype');
}
.about-me-logos{
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    margin: 4% 0;
}
.about-me-logos span{
    /*margin: 3%;*/
    font-size: 1.2em;
    flex-basis: 10%;
    min-width: 7vh;
}

.about-me-logos span a{
    color: var(--secondary-color);
}
.about-me-logos span:hover{
    color: #fd6864;
}

.npm-svg:hover{
    fill: #fd6864;
}
/* Mobile Landscape Screen Sizes */
@media only screen and (min-width: 480px) {
    .self-image{
        width: 30vw;
        height: 30vw;
    }

    .about-me-info{
        font-size: 0.8em;
    }
}

/* Desktop screen Sizes */
@media only screen and (min-width: 768px) {

    [class*='aboutme-col-']{
        align-self: center;
        flex-basis: 92%;
        /*background-color: red;*/
    }

    .about-me-image-sec p{
        font-size: 1em;
        padding-top: 1em;
        text-align: center;
    }

    .self-image{
        width: 20vw;
        height: 20vw;
    }

    .about-me-logos span{
        /*margin: 3%;*/
        font-size: 1em;
    }
}

@media only screen and (min-width: 1000px) {
    [class*='aboutme-col-']{
        margin-left: 0;
        flex-basis: 45%;
    }

    .self-image{
        padding: 7px;
    }
}