.contact-body-row{
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;
    margin: 1%;
    font-size: calc(10px + 2vmin);
    border-radius: 20px;
    font-family: "Trebuchet MS";
    background-color: var(--request-row);
    color: var(--secondary-color);

}

.contact-col{
    flex-basis: 84%;
    margin: 2% 8%;
}
.contact-col p{
    padding: 2% 0;
    margin-bottom: 0;
    border-top-left-radius: 0.8em;
    border-top-right-radius: 0.8em;
    /*background-color: #FD6864;*/
    text-align: center;
    font-size: 1.5em;

}

.contact-form{
    /*background-color: var(--form-base-color);*/
    padding: 7% 0;
    border-bottom-left-radius: 0.8em;
    border-bottom-right-radius: 0.8em;
    text-align: center;
    /*border: solid 1px var(--main-theme-color);*/
}

.ant-form-item-label > label {
    font-weight: bold;
    color: var(--secondary-color);
}

.extra-contact-info{
    color: orange;
}
.mail-div{
    font-size:0.8em;
    text-align: center;
}
.mail-img{
    width: 30px;
}

.contact-right-border{
    border-right: dashed 2px #fd6864;
    padding-top: 1em;
    padding-bottom: 1em;
    text-align: center;
}

.contact-right-border img:hover{
    transform: scale(1.2) rotate(-15deg);
}

.ant-btn-primary {
    color: white;
    font-weight: bold;
    background-color: #fd6864;
    border-color: #fd6864;
}

.ant-btn-primary:hover, .ant-btn-primary:focus {
    color: black;
    background-color: #fd6864;
    border-color: #fd6864;
}

.contact-right-border{
    display: none;
}

.ant-col-offset-4{
    margin-left: 0;
}
.ant-input-number{
    width: 100%;
}
/* Mobile Landscape Screen Sizes */
@media only screen and (min-width: 480px) {
    .ant-col-offset-4{
        margin-left: 16%;
    }
}

/* Desktop screen Sizes */
@media only screen and (min-width: 768px) {
    .contact-right-border{
        display: unset;
    }
    .contact-col{
        flex-basis: 50%;
        margin: 2% 2%;
    }
    .ant-col-offset-4{
        margin-left: 16.666%;
    }
    .contact-col p{
        font-size: 1.1em;
    }
}