.main-header-row{
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    align-items: flex-end;
    height: fit-content;
}

.header-logos{
    display: none;
}

.header-row-2{
    flex-basis: 100%;
}
.header-row-1{
    flex-basis: 100%;
}

.header-logos span{
    font-size: 1em;
    flex-basis: 10%;
}

.header-logos span a{
    color: var(--secondary-color);
}
.header-logos span:hover{
    color: #fd6864;
}
.header-div {
    min-height: 11vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2em);
    padding-left: 10px;
    font-family: SansationLight;
    letter-spacing: 6px;
}
.header-title{
    font-weight: bolder;
}
.header-subtitle{
    display: none;
    /*font-size: calc(10px + 3vh);*/
    /*letter-spacing: 2px;*/
}
@font-face {
    font-family: "SansationLight";
    src: local('SansationLight'), url(../../fonts/SansationLight.woff) format('woff');
}

/* Mobile Landscape Screen Sizes */
@media only screen and (min-width: 480px) {
    .header-title{
        font-weight: bolder;
        font-size: calc(10px + 5vh);
    }
    .header-subtitle{
        display: unset;
        font-size: calc(10px + 2vh);
        letter-spacing: 2px;
    }
}

/* Desktop screen Sizes */
@media only screen and (min-width: 768px) {

    .header-subtitle{
        display: unset;
        font-size: calc(10px + 3vh);
        letter-spacing: 2px;
    }

    .header-logos{
        display: flex;
        flex-flow: row nowrap;
        justify-content: flex-start;
        padding-left: 2vh;
    }

    .header-row-2{
        /*min-width: 100%;*/
        flex-basis: 18%;
        margin-left: 2%;
    }
    .header-row-1{
        /*min-width: 100%;*/
        flex-basis: 78%;
        margin-left: 2%;
    }
    .header-div{
        align-items: flex-start;
        font-size: calc(10px + 5vh);
    }
    .header-subtitle{
        font-size: calc(10px + 1vh);
        /*letter-spacing: 8px;*/
        letter-spacing: 10px;
    }

    .header-logos span{
        font-size: 1.3em;
        flex-basis: 35%;
    }
    .header-div {
        min-height: 11vh;
    }
}