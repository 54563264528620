.main-body-row {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    margin: 1%;
    font-size: calc(10px + 2vmin);
    border-radius: 20px;
    font-family: "Trebuchet MS";
    font-weight: bold;
    background-color: var(--request-row);

}

.main-body{
    border: solid 1px var(--main-theme-color);
    margin-top: 0.8em;
}

.footer{
    margin: 3% 0;
    padding-bottom: 1.5em;
    color: var(--secondary-color);
    text-align: center;
    font-family: "Trebuchet MS";
}
