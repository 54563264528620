.request-main-row {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    margin: 1%;
    font-size: calc(10px + 2vmin);
    border-radius: 14px;
    font-family: "Trebuchet MS";
    font-weight: bold;
    background-color: var(--request-row);
}

[class*="request-col"] {
    /*margin-top: 3%;*/
    margin: 3% 0;
    margin-left: 2%;
    padding: 0;
    font-size: calc(10px + 1vmin);
}

.request-col-1-2{

}
.request-col-1 {
    flex-basis: 96%;

}

.request-col-2 {
    flex-basis: 47%;
}

.col-1-row {
    display: flex;
    flex-flow: column nowrap;
    align-items: flex-start;
    justify-content: center;
}

.icon-col{
    display: flex;
    flex-flow: column;
    align-items: center;
    border-right: var(--secondary-color) 1px solid;
    padding-right: 14px;
}
.icon-col img{
    width: 2.5em;
    margin-bottom: 0.4em;
    margin-top: 0.5em;
}
.icon-label{
    font-size: 11px;
    /*color: #FD6864;*/
    color: #fd6864;
    margin-bottom: 0.5em;
    font-family: SansationLight;
}

.ant-switch{
    background-color: lightgrey;
    border-color: var(--main-theme-color);
}

.ant-switch-checked{
    background-color: #FD6864;
}

.input-select {
    display: flex;
    flex-flow: row wrap;
    justify-content: flex-start;
}

.ant-input.ant-input-disabled{
    display: none;
    font-weight: bold;
    border-color: var(--label-background-select);
    background-color: var(--label-background-select);
    color: var(--secondary-color);
}

.send-div{
    display: flex;
    flex-flow: row;
    justify-content: center;
    align-items: center;
}

.send-button {
    margin-top: 1vh;
    border-color: #FD6864;
    background-color: #FD6864;
    color: var(--primary-color);
    width: 100%;
}

.send-button:hover{
    border-color: #FD6864;
    background-color: #FD6864;
    color: var(--secondary-color);
}

.input-group-col {
    /*margin-left: 2%;*/
    flex-basis: 98%;
    width: 20%;
    /*margin-top: 0.5vh;*/
    border-radius: 5px;
}

.input-group-col-2{
    flex-basis: 98%;
    align-self: center;
    /*margin-top: 0.5vh;*/
}
.ant-input-group-addon {
    height: 2.3em;
    font-weight: bold;
    /*background-color: grey;*/
    /*border-color: grey;*/
    color: var(--secondary-color);
    background-color: var(--label-background-select);
    /*border-color: var(--main-theme-color);*/
    border-color: #fd6864;

}
.ant-select {
    min-width: 8em;
    color: var(--secondary-color);
    text-align: left;
    /*text-align-last: left;*/
}

.ant-input-group.ant-input-group-compact, .ant-input-group.ant-input-group-compact > .ant-select:last-child > .ant-select-selector, .ant-input-group.ant-input-group-compact > .ant-calendar-picker:last-child .ant-input, .ant-input-group.ant-input-group-compact > .ant-select-auto-complete:last-child .ant-input, .ant-input-group.ant-input-group-compact > .ant-cascader-picker:last-child .ant-input, .ant-input-group.ant-input-group-compact > .ant-cascader-picker-focused:last-child .ant-input, .ant-input-group.ant-input-group-compact > .ant-mention-wrapper:last-child .ant-mention-editor, .ant-input-group.ant-input-group-compact > .ant-time-picker:last-child .ant-time-picker-input {
    border-color: #fd6864;
}

.ant-select-arrow {
    color: var(--secondary-color);
}

.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    background-color: var(--label-background-select);
    border-color: var(--main-theme-color);
}

.select-option {
    color: var(--secondary-color);
    background-color: var(--primary-color);
}

.select-option:hover {
    background-color: var(--label-color);
}

.select-option:not(:hover) {
    background-color: var(--primary-color);
}

.select-option:not(:active) {
    color: var(--secondary-color);
}


/* Mobile Landscape Screen Sizes */
@media only screen and (min-width: 480px) {
    .icon-label{
        font-size: 3vw;
    }
    .col-1-row .lbl{
        font-size: 1em;
    }
}

/* Desktop screen Sizes */
@media only screen and (min-width: 768px) {
    [class*="request-col"] {
        margin: 1.5% 2%;
        margin-right: 0;
    }

    .request-col-1-2{
        flex-basis: 7%;
        /*margin-left: 2%;*/
    }
    .request-col-1 {
        flex-basis: 12.5%;
    }

    .request-col-2 {
        flex-basis: 60%;
        border-left: solid 1px var(--secondary-color);
    }

    .col-1-row{
        padding-top: 20px;
    }

    .col-1-row .lbl{
        font-size: 0.7em;
    }

    .icon-label {
        font-size: 15px;
        margin-bottom: 0.8em;
    }
    .icon-col img{
        width: 2.5em;
    }

}
