.response-body {
    display: flex;
    flex-flow: row wrap;
    /*justify-content: center;*/
    min-height: 17em;
    /*align-items: center;*/
    margin: 1%;
    margin-top: 0.8em;
    padding: 10px 5px;
    font-size: calc(10px + 2vmin);
    border-radius: 14px;
    font-family: "Trebuchet MS";
    font-weight: bold;
    background-color: var(--request-row);
    color: var(--secondary-color);
    border: solid 1px var(--main-theme-color);
}

.ant-tabs {
    min-width: 100%;
}

.ant-tabs.ant-tabs-card .ant-tabs-card-bar .ant-tabs-tab {
    background-color: var(--primary-color);
    color: var(--secondary-color);
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    border-color: var(--main-theme-color);
}

.ant-tabs-bar{
    border-bottom-color: var(--main-theme-color);
}

.ant-tabs.ant-tabs-card .ant-tabs-card-bar .ant-tabs-tab-active{
    background-color: #fd6864;
    color: white;
    font-weight: bolder;
}

.ant-tabs.ant-tabs-card .ant-tabs-card-content > .ant-tabs-tabpane, .ant-tabs.ant-tabs-editable-card .ant-tabs-card-content > .ant-tabs-tabpane{
    /*background-color: orange;*/
    color: var(--secondary-color);
    padding-left: 10px;
}

.pre.prettyprint{
    border: 0;
}